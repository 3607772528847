import React from 'react';
import { graphql } from 'gatsby';
import {
  translationsFactory,
  TranslationsContext,
  Letters,
  fallbackAlphabet,
  Translation,
  useTranslations,
} from 'settings/i18n';
import useLinkCreator from 'hooks/useLinkCreator';
import { useIsRtl } from 'hooks/useCurrentLocale';
import EducationNavigation from 'components/EducationNavigation';
import Footer from 'components/Footer';
import SEO, { SEOMeta } from 'components/SEO/SEO';
import Breadcrumbs from 'components/Breadcrumbs';
import { EducationHeading } from 'components/EducationTile/EducationTile';
import SocialShareBar from 'components/SocialShareBar';
import Heading from 'components/Heading/Heading';
import Text from 'components/Text/Text';
import whatIsBlockchain from 'images/what-is-blockchain.svg';
import whatIsTestnet from 'images/what-is-testnet.svg';
import proofOfWork from 'images/proof-of-work.png';
import pos from 'images/pos.svg';
import ethereum from 'images/ethereum.svg';
import {
  Main,
  Wrapper,
  Trapezoid,
  TrapezoidWrapper,
  ReversedLine,
  ReversedImage,
  ReversedExcerpt,
  ReversedTrapezoid,
  Line,
  Excerpt,
  CryptocurrencyHeading,
  ActionButtons,
  ActionButton,
  Image,
  OrnamentTrapezoid,
  RelativeContainer,
  ThirdSection,
  PdfActionButton,
  EducationVideoModal,
} from 'components/EducationSection/EducationSection';
import EducationGlossary from 'components/EducationGlossary/EducationGlossary';
import ClientOnly from 'components/ClientOnly/ClientOnly';
import useResponsivePdfLink, { PdfLinks } from 'hooks/useResponsivePdfLink';
import extractPdfUrlsFactory from 'settings/extract-pdf-urls';
import menuLinks from 'settings/menuLinks';
import useWindowSize from 'hooks/useWindowSize';

const EducationBlockchainProviders = ({
  data,
}: {
  data: {
    translations: Translation;
    letters: Letters;
    education: Translation;
    homepage: Translation;
    metadata: SEOMeta;
  };
}) => {
  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        ...data?.translations?.translation,
        ...data?.education?.translation,
        ...data?.homepage?.translation,
      },
    },
  });

  const extractPdfUrls = extractPdfUrlsFactory(data);
  const letters = data?.letters?.translation.letters || fallbackAlphabet;

  return (
    <TranslationsContext.Provider value={t}>
      <SEO metadata={data.metadata || undefined} url={menuLinks.blockchain} />
      <ClientOnly>
        <SocialShareBar pageTitle={data?.metadata?.seo?.title} />
        <EducationNavigation />
        <EducationBlockchain
          pdfs={{
            testnet: extractPdfUrls('whatIsTestnet', {
              fallbackDesktop: 'testnet-desktop_en',
              fallbackMobile: 'testnet-mobile',
            }),
            blockchain: extractPdfUrls('whatIsBlockchain', {
              fallbackDesktop: 'blockchain-desktop_en',
              fallbackMobile: 'blockchain-mobile_en',
            }),
            proofOfWork: extractPdfUrls('proofOfWork', {
              fallbackDesktop: 'proof-of-work-desktop_en',
              fallbackMobile: 'proof-of-work-mobile_en',
            }),
            pos: extractPdfUrls('pos', {
              fallbackDesktop: 'pos-desktop_en',
              fallbackMobile: 'pos-mobile_en',
            }),
            ethereum: extractPdfUrls('ethereum', {
              fallbackDesktop: 'ethereum-desktop_en',
              fallbackMobile: 'ethereum-mobile_en',
            }),
          }}
          letters={letters}
        />
        <Footer />
      </ClientOnly>
    </TranslationsContext.Provider>
  );
};

const EducationBlockchain = ({
  letters,
  pdfs,
}: {
  letters: Letters['translation']['letters'];
  pdfs: {
    testnet: PdfLinks;
    blockchain: PdfLinks;
    proofOfWork: PdfLinks;
    pos: PdfLinks;
    ethereum: PdfLinks;
  };
}) => {
  const t = useTranslations();
  const createLink = useLinkCreator();
  const isRtl = useIsRtl();

  const { width } = useWindowSize();

  const testnetPdfLink = useResponsivePdfLink(pdfs.testnet);
  const blockchainPdfLink = useResponsivePdfLink(pdfs.blockchain);
  const proofOfWorkPdfLink = useResponsivePdfLink(pdfs.proofOfWork);
  const posPdfLink = useResponsivePdfLink(pdfs.pos);
  const ethereumPdfLink = useResponsivePdfLink(pdfs.ethereum);

  return (
    <Main>
      <Wrapper>
        <EducationHeading
          isRtl={isRtl}
          size="heading"
          color="dark"
          weight="medium"
          letterSpacing="tight">
          {t('blockchain')}
        </EducationHeading>

        <Breadcrumbs
          links={[
            { to: createLink(menuLinks.home), name: t('home') },
            { to: createLink(menuLinks.education), name: t('education') },
            { to: createLink(menuLinks.blockchain), name: t('blockchain') },
          ]}
        />
      </Wrapper>

      <RelativeContainer isRtl={isRtl}>
        <Trapezoid>
          <Line>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal">
              1
            </Heading>
          </Line>
          <TrapezoidWrapper>
            <Excerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('storingDataOnTheChain')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('storingDataOnTheChainContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={blockchainPdfLink} />
                <ActionButton to={createLink(menuLinks.quizBlockchain)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </Excerpt>
            <Image src={whatIsBlockchain} alt={t('storingDataOnTheChain')} />
          </TrapezoidWrapper>
        </Trapezoid>

        <ReversedTrapezoid>
          <ReversedLine style={{ marginLeft: '0' }}>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal"
              style={{ background: '#e9f6ff' }}>
              2
            </Heading>
          </ReversedLine>
          <TrapezoidWrapper>
            <ReversedImage src={whatIsTestnet} alt={t('whatIsTestnet')} />
            <ReversedExcerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('whatIsTestnet')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{
                  marginTop: '6px',
                  paddingRight: '6px',
                }}>
                {t('whatIsTestnetContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={testnetPdfLink} />
              </ActionButtons>
            </ReversedExcerpt>
          </TrapezoidWrapper>
        </ReversedTrapezoid>

        <ThirdSection>
          <Line>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal">
              3
            </Heading>
          </Line>
          <TrapezoidWrapper>
            <Excerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('provingWhatYouDo')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('provingWhatYouDoContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={proofOfWorkPdfLink} />
                <ActionButton to={createLink(menuLinks.quizProofOfWork)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </Excerpt>
            <Image
              style={{ marginLeft: width > 768 ? '0' : '35px' }}
              src={proofOfWork}
              alt={t('provingWhatYouDo')}
            />
          </TrapezoidWrapper>
        </ThirdSection>

        <ReversedTrapezoid>
          <ReversedLine style={{ marginLeft: '0' }}>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal"
              style={{ background: '#e9f6ff' }}>
              4
            </Heading>
          </ReversedLine>
          <TrapezoidWrapper>
            <ReversedImage
              src={pos}
              alt={t('pos')}
              style={{ transform: 'scale(0.75)' }}
            />
            <ReversedExcerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('pos')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('posContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={posPdfLink} />
                <EducationVideoModal src="https://www.youtube.com/embed/PpDIWTCwJ9k">
                  {t('video')}
                </EducationVideoModal>
                <ActionButton to={createLink(menuLinks.quizPos)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </ReversedExcerpt>
          </TrapezoidWrapper>
        </ReversedTrapezoid>

        <ThirdSection>
          <Line>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal">
              5
            </Heading>
          </Line>
          <TrapezoidWrapper>
            <Excerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('ethereum')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('ethereumContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={ethereumPdfLink} />
                <EducationVideoModal src={t('ethereumYtVideoLink')}>
                  {t('video')}
                </EducationVideoModal>
                <ActionButton to={createLink(menuLinks.quizEthereum)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </Excerpt>
            <Image
              src={ethereum}
              alt={t('ethereumContent')}
              style={{ transform: 'scale(1.3)' }}
            />
          </TrapezoidWrapper>
        </ThirdSection>

        <OrnamentTrapezoid />
      </RelativeContainer>

      <EducationGlossary letters={letters} />
    </Main>
  );
};

export default EducationBlockchainProviders;

export const query = graphql`
  query($locale: String!) {
    translations: wpPage(
      title: { in: "glossary" }
      language: { locale: { eq: $locale } }
    ) {
      ...GlossaryTranslation
    }

    letters: wpPage(
      title: { in: "alphabet" }
      language: { locale: { eq: $locale } }
    ) {
      translation {
        letters
      }
    }

    metadata: wpPage(
      slug: { regex: "/\\b(\\w*education-blockchain\\w*)+(-\\d+)?\\b$/" }
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }

    education: wpPage(
      slug: { regex: "/\\b(\\w*education\\w*)+(-\\d+)?\\b$/" }
      language: { locale: { eq: $locale } }
    ) {
      translation {
        education
        home
        blockchain
        storingDataOnTheChain
        storingDataOnTheChainContent
        whatIsTestnet
        whatIsTestnetContent
        provingWhatYouDo
        provingWhatYouDoContent
        pos
        posContent
        pdf
        video
        quiz
        glossary
        whatIsTestnetPdfDesktop {
          slug
        }
        whatIsTestnetPdfMobile {
          slug
        }
        whatIsBlockchainPdfDesktop {
          slug
        }
        whatIsBlockchainPdfMobile {
          slug
        }
        proofOfWorkPdfDesktop {
          slug
        }
        proofOfWorkPdfMobile {
          slug
        }

        posPdfDesktop {
          slug
        }
        posPdfMobile {
          slug
        }

        ethereum
        ethereumContent
        ethereumYtVideoLink
        ethereumPdfDesktop {
          slug
        }
        ethereumPdfMobile {
          slug
        }
      }
    }
    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
      ...FooterTranslation
    }
  }
`;
